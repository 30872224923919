import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import {Button, ExternalLink} from '@gnomad/ui'

import Searchbox from './Searchbox'

const NavBarWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
  box-sizing: border-box;
  width: 100%;
  padding: 10px 30px;
  background-image: radial-gradient(1220px at 50% 50%,#5487fd,rgba(84,135,253,0) 25%),radial-gradient(circle at 100% 50%,rgba(211,178,255,.5) 0,rgba(211,178,255,0) 32%),radial-gradient(circle at 0 4%,rgba(242,191,191,.6) 0,rgba(212,181,208,0) 5.5%),radial-gradient(circle at 0 50%,rgba(255,225,215,.6) 0,rgba(255,210,197,.2) 10%,rgba(255,196,178,0) 21%),linear-gradient(225deg,#5d83fe,#8376f8);  

  a {
    color: white;
    text-decoration: none;
  }

  @media (max-width: 900px) {
    flex-direction: column;
    padding: 10px;
  }
`

const LogoWrapper = styled.div`
  @media (max-width: 900px) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 5px;
  }
`

const Logo = styled.div`
  color: white;
  font-size: 1.5em;
`

const ToggleMenuButton = styled(Button)`
  border: 1px solid #fafafa;
  background: black;
  color: white;

  @media (min-width: 901px) {
    display: none;
  }
`

const Menu = styled.ul`
  display: flex;
  flex-direction: row;
  padding: 0;
  margin: 0;
  list-style-type: none;

  a {
    padding: 0.5em;
  }

  @media (max-width: 900px) {
    flex-direction: column;
    width: 100%;
    height: ${props => (props.isExpanded ? 'auto' : 0)};

    a {
      display: inline-block;
      width: 100%;
      padding: 1em 0;
    }
  }
`

const OuterWrapper = styled.div`
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.23);

  @media print {
    display: none;
  }
`

const Notice = styled.div`
  padding: 0.75em 0.5em;
  background: rgb(17, 115, 187);
  color: white;
  text-align: center;

  a {
    color: white;
  }
`

class NavBar extends Component {
  state = {
    isExpanded: false,
  }

  toggleMenu = () => {
    this.setState(state => ({ ...state, isExpanded: !state.isExpanded }))
  }

  closeMenu = () => {
    this.setState({ isExpanded: false })
  }

  render() {
    const { isExpanded } = this.state
    return (
      <OuterWrapper>
        <NavBarWrapper>
          <LogoWrapper>
            <a href="https://www.illumina.com" target='_blank' onClick={this.closeMenu}>
              <Logo>Illumina PrimateAI-3D browser</Logo>
            </a>
            <ToggleMenuButton onClick={this.toggleMenu}>☰</ToggleMenuButton>
          </LogoWrapper>
          <Searchbox id="navbar-search" placeholder="Search" width="360px" />
          <Menu isExpanded={isExpanded}>
            {/*<li>*/}
            {/*  <Link to="/about" onClick={this.closeMenu}>*/}
            {/*    About*/}
            {/*  </Link>*/}
            {/*</li>*/}
            {/*<li>*/}
            {/*  /!* a instead of Link because the blog is a separate application *!/*/}
            {/*  <a href="/blog/">News</a>*/}
            {/*</li>*/}
            {/*<li>*/}
            {/*  <Link to="/downloads" onClick={this.closeMenu}>*/}
            {/*    Downloads*/}
            {/*  </Link>*/}
            {/*</li>*/}
            {/*<li>*/}
            {/*  <Link to="/terms" onClick={this.closeMenu}>*/}
            {/*    Terms*/}
            {/*  </Link>*/}
            {/*</li>*/}
            <li>
              <Link to="/" onClick={this.closeMenu}>
                Home
              </Link>
            </li>

            <li>
              <Link to="/publications" onClick={this.closeMenu}>
                Publications
              </Link>
            </li>
            <li>
              <Link to="/download" onClick={this.closeMenu}>
                Download
              </Link>
            </li>
            {/*<li>*/}
            {/*  <Link to="/contact" onClick={this.closeMenu}>*/}
            {/*    Contact*/}
            {/*  </Link>*/}
            {/*</li>*/}
            {/*<li>*/}
            {/*  /!* a instead of Link because the blog is a separate application *!/*/}
            {/*  <a href="/blog/changelog/">Changelog</a>*/}
            {/*</li>*/}
            <li>
              <Link to="/help" onClick={this.closeMenu}>
                Help
              </Link>
            </li>
          </Menu>
        </NavBarWrapper>
        {/*<Notice>*/}
        {/*  To download the full table of PrimateAI-3D scores, please contact {' '}*/}
        {/*  <ExternalLink href="mailto:primateAI_licensing@illumina.com">primateAI_licensing@illumina.com</ExternalLink>*/}
        {/*  {' '} to request an academic or commercial license.*/}
        {/*  <div>*/}
        {/*    Source code is publicly available at {' '}*/}
        {/*    <ExternalLink href="https://github.com/Illumina/PrimateAI-3D/">https://github.com/Illumina/PrimateAI-3D</ExternalLink>*/}
        {/*  </div>*/}
        {/*</Notice>*/}
      </OuterWrapper>
    )
  }
}

export default NavBar
